<!-- begin: Header -->
<div style=" box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;" ktHeader #ktHeader class="kt-header kt-grid__item" [ngClass]="htmlClassService.getClasses('header', true)" id="kt_header">
	<ngb-progressbar class="kt-loading-bar" *ngIf="(loader.progress$|async) > 0" [value]="loader.progress$|async" height="3px"></ngb-progressbar>
	<!-- <h1  style="width:30%" class="p-3 pt-4"><small>{{ time | date: 'mediumDate' }}</small> | {{ time | date: 'hh:mm:ss a' }}</h1> -->
	<h1  style="width:30%" class="p-3 pt-4">APP GAUM 
		<!-- <span style="color: red !important;">UAT</span> -->
	</h1>
	<!-- begin: Header Menu -->
	<kt-menu-horizontal></kt-menu-horizontal>
	<!-- end: Header Menu -->

	<!-- begin:: Header Topbar -->
	<!-- empty div to fix topbar to stay on the right when menu-horizontal is hidden -->
	<div *ngIf="!menuHeaderDisplay"></div>
	<kt-topbar></kt-topbar>
	<!-- end:: Header Topbar -->
</div>
<!-- end: Header -->
