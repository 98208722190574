<div class="row" style="margin-top: 20vh; border: 2px solid #ccc; padding-top: 50px;padding-bottom: 50px;">
    <div class="col-md-4"></div>
    <div class="col-md-4">
        <div class="kt-login__body">
            <div class="kt-login__form">
                <div class="kt-login__title mb-3">
                    <span style="font-size: large !important; font-weight: 600;">Log In</span>
                </div>
                <kt-auth-notice></kt-auth-notice>
                <form autocomplete="off" class="kt-form" (ngSubmit)="onSubmit()" [formGroup]="loginForm" autocomplete="off">
                    <div class="form-group">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Username</mat-label>
                            <input matInput type="text" placeholder="Username" formControlName="username" autocomplete="off" />
                            <mat-error *ngIf="isControlHasError('username','required')">
                                <strong>Username Required</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('username','minlength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('username','maxlength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 320</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="form-group mt-2">
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Password</mat-label>
                            <input matInput [type]="password" placeholder="{{ 'AUTH.INPUT.PASSWORD' | translate }}" formControlName="password" autocomplete="off" />
                            <a href="javascript:;" mat-button matSuffix mat-icon-button aria-label="Clear" (click)="changeType()">
                                <mat-icon *ngIf="!eyeView">visibility</mat-icon>
                                <mat-icon *ngIf="eyeView">visibility_off</mat-icon>
                            </a>
                            <mat-error *ngIf="isControlHasError('password','required')">
                                <strong>Password Required</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('password','minlength')">
                                <strong>{{ 'AUTH.VALIDATION.MIN_LENGTH_FIELD' | translate }} 3</strong>
                            </mat-error>
                            <mat-error *ngIf="isControlHasError('password','maxlength')">
                                <strong>{{ 'AUTH.VALIDATION.MAX_LENGTH_FIELD' | translate }} 100</strong>
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="kt-login__actions">
                        <button autofocus type="submit" [disabled]="loginForm.invalid" [ngClass]="{'kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light': loading}"id="kt_login_signin_submit" class="btn btn-primary btn-elevate kt-login__btn-primary ml-auto">LOGIN</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <div class="col-md-4"></div>
</div>