// Angular
import {
	ChangeDetectorRef,
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
// RxJS
import { Observable, Subject } from "rxjs";
import { finalize, takeUntil, tap } from "rxjs/operators";
// Translate
import { TranslateService } from "@ngx-translate/core";
// Store
import { Store } from "@ngrx/store";
import { AppState } from "../../../../core/reducers";
// Auth
import { AuthNoticeService, Login } from "../../../../core/auth";

import { AuthService } from "../../../../core/auth/_services/auth.service";
import { UserLogin } from "../../auth/login/userLogin";
import { ToastrService } from 'ngx-toastr';

@Component({
	selector: "kt-login",
	templateUrl: "./login-two.component.html",
	encapsulation: ViewEncapsulation.None,
})
export class LoginTwoComponent implements OnInit, OnDestroy {
	// Public params
	loginForm: FormGroup;
	loading = false;
	isLoggedIn$: Observable<boolean>;
	errors: any = [];
	userName: string;
	public eyeView: boolean = false;
	public password: any = "password";

	private unsubscribe: Subject<any>;

	private returnUrl: any;
	userId: string;
	userNumber: any;
	lastSeen: any;


	/**
	 * Component constructor
	 *
	 * @param router: Router
	 * @param auth: AuthService
	 * @param authNoticeService: AuthNoticeService
	 * @param translate: TranslateService
	 * @param store: Store<AppState>
	 * @param fb: FormBuilder
	 * @param cdr
	 * @param route
	 */
	constructor(
		private router: Router,
		private auth: AuthService,
		private authNoticeService: AuthNoticeService,
		private translate: TranslateService,
		private store: Store<AppState>,
		private fb: FormBuilder,
		private cdr: ChangeDetectorRef,
		private route: ActivatedRoute,
		private toastr: ToastrService,

	) {
		this.unsubscribe = new Subject();
	}

	/**
	 * On init
	 */
	ngOnInit(): void {
		// this.auth.microsoft().subscribe((res) => {
		// 	console.log(res);
		// 	let ssoLink = res.toString();
		// 	window.location.href = ssoLink;
		//   },(error) => {
		// 	console.log(error);
		// 	});
			
		this.initLoginForm();

		// redirect back to the returnUrl before login
		this.route.queryParams.subscribe((params) => {
			this.returnUrl = params.returnUrl || "/";
		});
	}

	/**
	 * On destroy
	 */
	ngOnDestroy(): void {
		this.authNoticeService.setNotice(null);
		this.unsubscribe.next();
		this.unsubscribe.complete();
		this.loading = false;
	}

	/**
	 * Form initialization
	 */
	initLoginForm() {
			const initialNotice = `Please use your GCPL <b>username</b> and <b>password</b> to continue.`;
			this.authNoticeService.setNotice(initialNotice, "info");

		this.loginForm = this.fb.group({
			username: [
				"",
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(320),
				]),
			],
			password: [
				"",
				Validators.compose([
					Validators.required,
					Validators.minLength(3),
					Validators.maxLength(100),
				]),
			]
		});
	}

	/**
	 * Form Submit
	 */
	onSubmit() {
		let login = this.loginForm.value;
		this.login(login);
	}

	// User Login
	login(loginUser: UserLogin) {
		this.auth.userLogin(loginUser).subscribe(
			(res) => {
				var succ = res;
				if (succ) {
					localStorage.setItem("moduleList", JSON.stringify(succ['moduleList']));
					localStorage.setItem("permissions", JSON.stringify(succ['permissions']));
					localStorage.setItem("token", succ["userToken"]);
					localStorage.setItem("isRefreshed", 'true');
					localStorage.setItem("lastSeen", succ['userData']['lastSeen']);
					localStorage.setItem('roleId', succ['userData']['roleId']);
					localStorage.setItem('role', succ['userData']['role']);
					localStorage.setItem('userId', succ['userData']['id']);
					let fullName = succ['userData']['firstName'] + ' ' + succ['userData']['lastName'] ;
					localStorage.setItem('fullName', fullName);
					this.userName = succ['userData']['name'];
					this.userId = btoa(succ['userData']['id']);
					this.userNumber = succ['userData']['mobile'];
					this.lastSeen = succ['userData']['lastSeen'];

				this.getUserData();

				this.router.navigate(['/transactions/approvals']);
					this.toastr.success('Welcome', 'Successfully Login', {
						timeOut: 3000
					});
				}
			},
			(err) => {
				this.toastr.error('Sorry', err['error']['message'], {
					timeOut: 3000
				});
			}
		);
	}

	getUserData() {
		let userData = [{
			"id": this.userId,
			"name": this.userName,
			"number": this.userNumber,
			"lastSeen": this.lastSeen
		}]
		this.auth.saveUserData(userData);

	}

	// Password Eye Toggle
	changeType() {
		if (this.password == "password") {
			this.password = "text";
			this.eyeView = true;
		} else {
			this.password = "password";
			this.eyeView = false;
		}
	}

	// submit() {
	// 	const controls = this.loginForm.controls;
	// 	/** check form */
	// 	if (this.loginForm.invalid) {
	// 		Object.keys(controls).forEach(controlName =>
	// 			controls[controlName].markAsTouched()
	// 		);
	// 		return;
	// 	}

	// 	this.loading = true;

	// 	const authData = {
	// 		email: controls.email.value,
	// 		password: controls.password.value
	// 	};
	// 	this.auth
	// 		.login(authData.email, authData.password)
	// 		.pipe(
	// 			tap(user => {
	// 				if (user) {
	// 					this.store.dispatch(new Login({authToken: user.accessToken}));
	// 					this.router.navigateByUrl(this.returnUrl); // Main page
	// 				} else {
	// 					this.authNoticeService.setNotice(this.translate.instant('AUTH.VALIDATION.INVALID_LOGIN'), 'danger');
	// 				}
	// 			}),
	// 			takeUntil(this.unsubscribe),
	// 			finalize(() => {
	// 				this.loading = false;
	// 				this.cdr.markForCheck();
	// 			})
	// 		)
	// 		.subscribe();
	// }

	/**
	 * Checking control validation
	 *
	 * @param controlName: string => Equals to formControlName
	 * @param validationType: string => Equals to valitors name
	 */
	isControlHasError(controlName: string, validationType: string): boolean {
		const control = this.loginForm.controls[controlName];
		if (!control) {
			return false;
		}

		const result =
			control.hasError(validationType) &&
			(control.dirty || control.touched);
		return result;
	}

}
